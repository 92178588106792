.btnCustom,
.messageCustomRight,
.messageCustomLeft,
.messageSuggestion,
.chatContainer,
.styledInput,
.heeboText,
.summitTitleName,
.chatHeaderContainer {
  font-family: "Raleway", sans-serif;
}

.chatTitle,
.messageStartings {
  font-family: "Heebo", sans-serif;
}

#chat-widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  padding: 0px !important;
  border: none;
  background-color: transparent;
}

#chat-widget button:hover {
  background-color: transparent;
  border: none !important;
  border-radius: 999px;
}

#chat-widget button:active {
  background-color: transparent;
  border: none !important;
  border-radius: 999px;
}

#chat-widget ::after ::before :focus {
  background-color: transparent;
  border: none !important;
  border-radius: 999px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btnCustom {
  background-color: transparent !important;
  color: #808080 !important;
  border: none !important;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  border-radius: 4px;
}

.btnCustom:hover {
  background-color: #b4b4b4 !important;
}

.messageCustomRight {
  background-color: #234a52 !important;
  max-width: 379px;
  color: white;
  padding: 16px 18px;
  border-radius: 8px;
  min-height: 20px;
  white-space: normal;
}

.messageCustomLeft {
  background-color: #49ba76 !important;
  max-width: 379px;
  color: white;
  padding: 16px 18px;
  border-radius: 8px;
  min-height: 20px;
  white-space: normal;
  overflow-wrap: break-word;
}

.messageSuggestion {
  color: #234a52;
  padding: 8px 16px;
  border-radius: 16px;
  white-space: normal;
  cursor: pointer;
  background-color: #f5f5f5;
  display: flex;
  text-align: left;
  gap: 24px;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  font-family: "Raleway" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  line-height: 22.4px;
  text-align: left;
}

.messageSuggestion:hover {
  background-color: #3c8391;
  color: #ffffff;
}

.messageStartings {
  color: #49ba76;
  padding: 8px 16px;
  border-radius: 100px;
  min-height: 20px;
  border: 1px solid #49ba76;
  white-space: normal;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
  text-transform: uppercase;
  transition: background-color 0.5s ease;
}

.messageStartings:hover {
  background-color: #49ba76;
  color: #ffffff;
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(100%);
  }
}

.chatContainer {
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  position: fixed;
  bottom: 24px;
  right: 24px;
  max-width: 512px;
  height: calc(100% - 196px);
  max-height: 900px;
  display: flex;
  flex-direction: column;
  background: white;
  box-shadow: 0px 0px 25px 0px #00000040;
  border-radius: 8px;
  overflow: hidden;
  z-index: 99;
}

@media (max-height: 1024px) {
  .chatContainer {
    height: calc(100% - 196px);
  }
}

@media (max-width: 1440px) {
  .chatContainer {
    right: 10px;
    bottom: 10px;
    max-width: 40%;
    height: calc(100% - 164px);
  }
}

@media (max-width: 1280px) {
  .chatContainer {
    right: 10px;
    bottom: 10px;
    max-width: 45%;
    height: calc(100% - 164px);
  }
}

@media (max-width: 1024px) {
  .chatContainer {
    right: 10px;
    bottom: 50px;
    max-width: 50%;
    height: calc(100% - 164px);
  }
  .chatButton {
    bottom: 50px !important;
  }
  .greeting {
    font-size: 32px !important;
  }
}

@media (max-width: 768px) {
  .chatContainer {
    right: 10px;
    bottom: 50px;
    max-width: 70%;
    height: calc(100% - 164px);
  }
  .chatButton {
    bottom: 50px !important;
  }
}

@media (max-width: 480px) {
  .chatContainer {
    bottom: 50px;
    height: calc(100%-164px);
    max-width: 98%;
    right: 5px;
  }
  .chatTitle {
    font-size: 24px !important;
  }
  .greeting {
    font-size: 20px !important;
  }
  .needHelp {
    padding: 10px 8px !important;
  }
  .needHelpLabel {
    font-size: 16px !important;
  }
  .needHelpSmall {
    font-size: 12px !important;
  }
}

.chatContainer.open {
  animation-name: slideIn;
}

.chatContainer.close {
  animation-name: slideOut;
}

.styledInput {
  font-size: 16px;
  padding: 12px 20px;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 50px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  background-color: #ecf1f4;
  border: 1px solid #d9d9d9;
}

.styledInput:focus {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.styledInput::placeholder {
  color: #373737;
}

.chatHeaderContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 84px;
  border-bottom: 1px solid #d9d9d9;
  justify-content: space-between;
  padding: 18px 16px;
  gap: 16px;
}

/* General Styles */
.chatButton {
  position: fixed;
  bottom: 36px;
  right: 36px;
  z-index: 2;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 9999px;
}

.chatHeader {
  width: 50px;
  height: 50px;
}

.chatTitle {
  font-weight: 700;
  font-size: 32px;
  white-space: nowrap;
  color: #234a52 !important;
}

.summitTitleName {
  white-space: wrap;
  color: #234a52 !important;
  font-family: Raleway;
  font-size: 18px;
  font-weight: 700;
  line-height: 21.13px;
  text-align: left;
  letter-spacing: normal;
}

.chatTitleSpan {
  color: #56c288;
}

.headerButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.messagesContainer {
  overflow-y: auto;
  flex: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow-x: hidden;
  justify-content: space-between;
}

.startingButtonsContainer {
  display: flex;
  flex-direction: row;
  flex-shrink: 1;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: flex-start;
}

.inputContainer {
  padding: 20px;
  display: flex;
  gap: 8px;
}

.sendButton {
  background-color: #d46540 !important;
  border-radius: 100px;
  padding: 16px;
  border: none;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
}

.sendButton:hover {
  background-color: #c55e3b !important;
  border-radius: 999px;
}

.styledInput.inputInvalid {
  border: 1px solid red;
}

.sendButton:disabled {
  background-color: #ababab !important;
  cursor: not-allowed;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.suggestion-item {
  animation: fadeIn 0.5s ease forwards;
}

.needHelp {
  color: white;
  background-color: #234a52;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 14px;
  gap: 8px;
  cursor: pointer;
}

.needHelp:hover {
  background-color: #3e6b77;
}

.urlStyle {
  text-decoration: none !important;
  cursor: pointer;
  white-space: pre-wrap !important;
  font-family: "Raleway" !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  line-height: 22.4px !important;
  text-align: left !important;
  color: #264aff !important;
}

.header {
  font-size: 18px !important;
  font-weight: 700;
  color: #234a52 !important;
  font-family: "Raleway";
  display: flex;
  align-items: center;
  gap: 8px;
}

.messageTime {
  font-size: 14px;
  color: #888;
  align-self: end;
  white-space: nowrap;
  font-weight: 400;
  font-family: "Raleway";
}

.chatLoader {
  max-width: 379px !important;
  padding: 16px 18px !important;
  border-radius: 8px !important;
  min-height: 20px !important;
}

.greeting {
  display: flex;
  font-size: 32px;
  font-weight: 700;
  color: #234a52;
  padding-bottom: 16;
}
